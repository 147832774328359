import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"
import * as Yup from "yup"
import { useFormik } from "formik"

//import components
// 18
// import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

import GenreModal from "./ViewModal"

import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap"

import { SimpleStringValue } from "helpers/common_helper_functions"
import { SaveToast } from "components/Common/SaveToast"

import {
  createGenre,
  getAllGenre,
  updateGenre,
  deleteGenre,
} from "helpers/backendHelpers/genre"
import SubmitLoader from "components/Common/SubmitLoader"

function Genre() {
  //meta title
  document.title = "Genre | LMS Ghana"

  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [save, setSave] = useState(false)

  const [genre, setGenre] = useState({})
  const [genres, setGenres] = useState([])

  const [deleteModal, setDeleteModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [submitLoading, setsubmitLoading] = useState(false)

  useEffect(() => {
    getAllGenres()
  }, [save])

  const getAllGenres = async () => {
    try {
      setLoading(true)
      let response = await getAllGenre()
      let { genres } = response.data || {}
      genres = genres || []
      setGenres(genres)
      setLoading(false)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem fetching genre"
      setGenres([])
      setLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleAddGenreSubmit = async data => {
    try {
      setsubmitLoading(true)
      let response = await createGenre(data)
      let message = response?.message || "Genre Created Successfully"
      SaveToast({ message, type: "success" })
      validation.resetForm()
      toggle()
      setSave(prevSave => !prevSave)
      setsubmitLoading(false)
      return
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem creating Genre"
      setsubmitLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleEditGenreSubmit = async (id, data) => {
    if (!id) {
      return SaveToast({
        message: "Please enter genre id",
        type: "error",
      })
    }

    try {
      setsubmitLoading(true)
      let response = await updateGenre(id, data)
      let message = response?.message || "Genre Updated Successfully"
      SaveToast({ message, type: "success" })

      validation.resetForm()
      toggle()
      setSave(prevSave => !prevSave)
      setsubmitLoading(false)
      return
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem creating Genre"
      setsubmitLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleUpdateGenreStatus = async (bg_id, bg_status) => {
    if (!bg_id) {
      return SaveToast({
        message: "Please enter Genre id",
        type: "error",
      })
    }

    try {
      let response = await updateGenre(bg_id, { bg_status })
      let message = response?.message || "Genre Status Updated Successfully"
      SaveToast({ message, type: "success" })

      setSave(prevSave => !prevSave)
      return
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem updating genre status"
      return SaveToast({ message, type: "error" })
    }
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      bg_genreName: (genre && genre.bg_genreName) || "",
      bg_status: (genre && genre.bg_status) || true,
    },
    validationSchema: Yup.object({
      bg_genreName: Yup.string().required("Please Enter Genre Name"),
      bg_status: Yup.bool().required("Please Select Status"),
    }),
    onSubmit: values => {
      let GenreData = {
        bg_genreName: values.bg_genreName,
        bg_status: values.bg_status,
      }

      if (isEdit) {
        return handleEditGenreSubmit(genre.bg_id, GenreData)
      } else {
        return handleAddGenreSubmit(GenreData)
      }
    },
  })

  const toggleViewModal = () => {
    if (modal1) {
      setGenre({})
    }
    setModal1(!modal1)
  }

  const toggle = () => {
    if (modal) {
      setGenre(null)
    }
    setModal(!modal)
  }
  const handleGenreClick = async arg => {
    const Genre = arg

    setGenre({
      bg_id: Genre.bg_id,
      bg_genreName: Genre.bg_genreName,
      bg_status: Genre.bg_status,
    })
    setIsEdit(true)
    toggle()
  }

  const onClickDelete = genre => {
    setGenre(genre)
    setDeleteModal(true)
  }

  const handleDeleteGenre = async () => {
    if (!genre.bg_id) {
      return SaveToast({ message: "Invalid Genre ID", type: "error" })
    }

    try {
      const response = await deleteGenre(genre.bg_id)
      let message = response?.message || "Genre Deleted Successfully"
      SaveToast({ message, type: "success" })

      setSave(prevSave => !prevSave)
      setDeleteModal(false)
      setGenre({})
      return
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem deleting genre"
      return SaveToast({ message, type: "error" })
    }
  }

  const handleGenreClicks = () => {
    setIsEdit(false)
    toggle()
  }

  const handleDeleteModalCloseClick = () => {
    setDeleteModal(false)
    setGenre({})
  }

  const columns = useMemo(
    () => [
      {
        Header: "Genre ID",
        accessor: "bg_id",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Genre Name",
        accessor: "bg_genreName",
        width: "150px",
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "bg_status",
        disableFilters: true,
        Cell: cellProps => {
          const cellData = cellProps.row.original
          return (
            <div className="form-check form-switch form-switch-md">
              <input
                type="checkbox"
                className="form-check-input"
                id={`bg_status_checkbox-${cellData.bg_id}`}
                name={`bg_status_checkbox-${cellData.bg_id}`}
                defaultChecked={cellData.bg_status}
                onChange={e => {
                  let { checked, name } = e.target
                  document.getElementById(name).checked = checked
                  return handleUpdateGenreStatus(cellData.bg_id, checked)
                }}
              />
            </div>
          )
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-dark"
                onClick={() => {
                  setGenre(cellProps.row.original)
                  toggleViewModal()
                }}
              >
                <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
                <UncontrolledTooltip placement="top" target="viewtooltip">
                  View
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const genreData = cellProps.row.original
                  handleGenreClick(genreData)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const genreData = cellProps.row.original
                  onClickDelete(genreData)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const printColumns = useMemo(
    () => [
      {
        Header: "Genre ID",
        accessor: "bg_id",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Genre Name",
        accessor: "bg_genreName",
        width: "150px",
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "bg_status",
        disableFilters: true,
        Cell: cellProps => {
          return cellProps?.row?.original?.bg_status ? "Active" : "Inactive"
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <GenreModal isOpen={modal1} toggle={toggleViewModal} genre={genre} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteGenre}
        onCloseClick={handleDeleteModalCloseClick}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={genres}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    handleAddButtonClick={handleGenreClicks}
                    addButtonLabel={"Add Genre"}
                    customPageSize={10}
                    className="custom-header-css"
                    // canExportCsv={true}
                    dataFetchLoading={loading}
                    noDataMessage="No system activity found."
                    canPrint={true}
                    printColumns={printColumns}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            isOpen={modal}
            toggle={toggle}
            centered={true}
            style={submitLoading ? { position: "relative" } : {}}
          >
            {submitLoading ? <SubmitLoader /> : <></>}
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Genre" : "Add Genre"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">
                        Genre <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="bg_genreName"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.bg_genreName || ""}
                        invalid={
                          validation.touched.bg_genreName &&
                          validation.errors.bg_genreName
                            ? true
                            : false
                        }
                      />
                      {validation.touched.bg_genreName &&
                      validation.errors.bg_genreName ? (
                        <FormFeedback type="invalid">
                          {validation.errors.bg_genreName}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                        disabled={submitLoading}
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  )
}
Genre.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Genre
