import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"
import * as Yup from "yup"
import { useFormik } from "formik"

// import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"
import RegionDistrictCircuitDropDownAllSelectable from "components/Common/RegionDistrictCircuitDropDownAllSelectable"
import { defaultRDCSeparator } from "helpers/common_helper_functions"
import TutorModal from "./ViewModal"

import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap"

import { SimpleStringValue } from "helpers/common_helper_functions"
import { SaveToast } from "components/Common/SaveToast"

import {
  getAllTutors,
  createTutor,
  updateTutor,
  deleteTutor,
} from "../../helpers/backendHelpers/tutorManagement"

import SubmitLoader from "components/Common/SubmitLoader"

function Tutors() {
  document.title = "Tutors | LMS Ghana"

  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const [tutor, setTutor] = useState(null)
  const [tutors, setTutors] = useState([])
  const [loading, setLoading] = useState(false)
  const [saved, setSaved] = useState(false)
  const [submitLoading, setsubmitLoading] = useState(false)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      tu_fullName: (tutor && tutor.tu_fullName) || "",
      tu_email: (tutor && tutor.tu_email) || "",
      tu_phoneNumber: (tutor && tutor.tu_phoneNumber) || "",
      tu_areaValue: (tutor && tutor.tu_areaValue) || "",
    },
    validationSchema: Yup.object({
      tu_fullName: Yup.string().required("Please Enter Your Full Name"),
      tu_email: Yup.string().email().required("Please Enter Your Email"),
      tu_phoneNumber: Yup.string()
        .required("Please Enter Your Phone Number")
        .matches(/^[0-9]{10}$/, "Please Enter Valid Phone Number"),
      tu_areaValue: Yup.mixed().test(
        "invalidInput",
        "Please Select Region-District-Circuit",
        value => {
          return !!value
        }
      ),
    }),
    onSubmit: async values => {
      let tutorData = values
      let [tu_region, tu_district, tu_circuit] =
        (values?.tu_areaValue + "" || "")?.split(defaultRDCSeparator) || []
      tu_region = tu_region || null
      tu_district = tu_district || null
      tu_circuit = tu_circuit || null

      if (isEdit) {
        tutorData["tu_region"] = tu_region
        tutorData["tu_district"] = tu_district
        tutorData["tu_circuit"] = tu_circuit
        delete tutorData["tu_id"]
        return handleEditTutorSubmit(tutor?.tu_id, tutorData)
      } else {
        tutorData["tu_region"] = tu_region
        tutorData["tu_district"] = tu_district
        tutorData["tu_circuit"] = tu_circuit
        return handleAddTutorSubmit(tutorData)
      }
    },
  })

  const toggleViewModal = () => {
    setModal1(!modal1)
    if (tutor) {
      setTutor(null)
    }
  }

  useEffect(() => {
    fetchTutors()
  }, [saved])

  const fetchTutors = async () => {
    try {
      setLoading(true)
      let response = await getAllTutors()
      let { tutors } = response.data || {}
      tutors = tutors || []
      setTutors(tutors)
      setLoading(false)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Fetching Tutors"
      setTutors([])
      setLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleAddTutorSubmit = async data => {
    try {
      setsubmitLoading(true)
      let response = await createTutor(data)
      let message = response?.message || "Tutor Created Successfully"
      SaveToast({ message, type: "success" })
      validation.resetForm()
      toggle()
      setSaved(prevSaved => !prevSaved)
      setsubmitLoading(false)
      return
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem creating Tutor"
      setsubmitLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleEditTutorSubmit = async (id, data) => {
    if (!id) {
      return SaveToast({
        message: "Please Enter Tutor Id",
        type: "error",
      })
    }

    try {
      setsubmitLoading(true)
      let response = await updateTutor(id, data)
      let message = response?.message || "Tutor Updated Successfully"
      SaveToast({ message, type: "success" })

      validation.resetForm()
      toggle()
      setSaved(prevSaved => !prevSaved)
      setsubmitLoading(false)
      return
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Creating Tutor"
      setsubmitLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleUpdateTutorStatus = async (tu_id, tu_status) => {
    if (!tu_id) {
      return SaveToast({
        message: "Please Enter Tutor Id",
        type: "error",
      })
    }

    try {
      let response = await updateTutor(tu_id, { tu_status })
      let message = response?.message || "Tutor Status Updated Successfully"
      SaveToast({ message, type: "success" })

      setSaved(prevSaved => !prevSaved)
      return
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Updating Tutor Status"
      return SaveToast({ message, type: "error" })
    }
  }

  const toggle = () => {
    if (modal) {
      setModal(false)
      setTutor(null)
    } else {
      setModal(true)
    }
  }

  const handleTutorClick = tVal => {
    const tutor = tVal

    let { tu_region, tu_district, tu_circuit } = tutor

    let areaValue = ""

    if (tu_circuit) {
      let RDCValue = `${tu_region || null}${defaultRDCSeparator}${
        tu_district || null
      }${defaultRDCSeparator}${tu_circuit || null}`

      areaValue = RDCValue
    }

    if (tu_circuit == null) {
      let RDvalue = `${tu_region || null}${defaultRDCSeparator}${
        tu_district || null
      }${defaultRDCSeparator}`

      areaValue = RDvalue
    }

    setTutor({
      tu_id: tutor.tu_id,
      tu_fullName: tutor.tu_fullName,
      tu_email: tutor.tu_email,
      tu_phoneNumber: tutor.tu_phoneNumber,
      tu_areaValue: areaValue,
    })

    setIsEdit(true)
    toggle()
  }
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = tutor => {
    setTutor(tutor)
    setDeleteModal(true)
  }
  const handleCloseClick = () => {
    setDeleteModal(false)
    setTutor({})
  }

  const handleDeleteTutor = async () => {
    if (!tutor.tu_id) {
      return SaveToast({ message: "Invalid Tutor ID", type: "error" })
    }

    try {
      const response = await deleteTutor(tutor.tu_id)
      let message = response?.message || "Team Member Deleted Successfully"
      SaveToast({ message, type: "success" })

      setSaved(prevSaved => !prevSaved)
      setDeleteModal(false)
      setTutor({})
      return
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem deleting team member"
      return SaveToast({ message, type: "error" })
    }
  }
  const handleAddButtonClick = () => {
    setIsEdit(false)
    toggle()
  }

  const columns = useMemo(
    () => [
      {
        Header: "Full Name",
        accessor: "tu_fullName",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Email",
        accessor: "tu_email",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Phone Number",
        accessor: "tu_phoneNumber",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Circuit",
        accessor: "tu_circuit",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "tu_status",
        disableFilters: true,
        Cell: cellProps => {
          const cellData = cellProps.row.original
          return (
            <div className="form-check form-switch form-switch-md">
              <input
                type="checkbox"
                className="form-check-input"
                id={`tu_status_checkbox-${cellData.tu_id}`}
                name={`tu_status_checkbox-${cellData.tu_id}`}
                defaultChecked={cellData.tu_status}
                onChange={e => {
                  let { checked, name } = e.target
                  document.getElementById(name).checked = checked
                  return handleUpdateTutorStatus(cellData.tu_id, checked)
                }}
              />
            </div>
          )
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-dark"
                onClick={() => {
                  setTutor(cellProps.row.original)
                  toggleViewModal()
                }}
              >
                <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
                <UncontrolledTooltip placement="top" target="viewtooltip">
                  View
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const tutorData = cellProps.row.original
                  handleTutorClick(tutorData)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const tutorData = cellProps.row.original
                  onClickDelete(tutorData)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <TutorModal isOpen={modal1} toggle={toggleViewModal} tutor={tutor} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteTutor}
        onCloseClick={handleCloseClick}
      />
      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="Tutors" breadcrumbItem="Tutor" /> */}
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={tutors}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    handleAddButtonClick={handleAddButtonClick}
                    addButtonLabel={"Add Tutor"}
                    customPageSize={10}
                    className="custom-header-css"
                    canExportCsv={true}
                    dataFetchLoading={loading}
                    canPrint={false}
                    noDataMessage="No system activity found."
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            isOpen={modal}
            toggle={toggle}
            style={submitLoading ? { position: "relative" } : {}}
          >
            {submitLoading ? <SubmitLoader /> : <></>}
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Tutor" : "Add Tutor"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">
                        Full Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="tu_fullName"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.tu_fullName || ""}
                        invalid={
                          validation.touched.tu_fullName &&
                          validation.errors.tu_fullName
                            ? true
                            : false
                        }
                      />
                      {validation.touched.tu_fullName &&
                      validation.errors.tu_fullName ? (
                        <FormFeedback type="invalid">
                          {validation.errors.tu_fullName}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">
                        Email <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="tu_email"
                        type="tu_email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.tu_email || ""}
                        invalid={
                          validation.touched.tu_email &&
                          validation.errors.tu_email
                            ? true
                            : false
                        }
                      />
                      {validation.touched.tu_email &&
                      validation.errors.tu_email ? (
                        <FormFeedback type="invalid">
                          {validation.errors.tu_email}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">
                        Phone Number <span className="text-danger">*</span>{" "}
                      </Label>
                      <Input
                        name="tu_phoneNumber"
                        type="text"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.tu_phoneNumber || ""}
                        invalid={
                          validation.touched.tu_phoneNumber &&
                          validation.errors.tu_phoneNumber
                            ? true
                            : false
                        }
                      />
                      {validation.touched.tu_phoneNumber &&
                      validation.errors.tu_phoneNumber ? (
                        <FormFeedback type="invalid">
                          {validation.errors.tu_phoneNumber}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <RegionDistrictCircuitDropDownAllSelectable
                        isRequired={true}
                        fieldName="tu_areaValue"
                        hasTouched={validation.touched.tu_areaValue}
                        hasErrors={validation.errors.tu_areaValue}
                        areaValue={validation.values.tu_areaValue}
                        setFieldValue={validation.setFieldValue}
                        setFieldTouched={validation.setFieldTouched}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                        disabled={submitLoading}
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  )
}
Tutors.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Tutors
