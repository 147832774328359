import React, { useMemo, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"

//import components
// import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

import { SimpleStringValue } from "helpers/common_helper_functions"
import { SaveToast } from "components/Common/SaveToast"

import { Col, Row, UncontrolledTooltip, Card, CardBody } from "reactstrap"
import {
  getAllSchool,
  updateSchool,
  deleteSchool,
  getAllSchoolWithFullSearchAPI,
  getAllSchoolColumnWiseSearchAPI,
} from "helpers/backendHelpers/school"

const Schools = props => {
  document.title = "Schools | LMS Ghana"

  const [deleteModal, setDeleteModal] = useState(false)
  const [schools, setSchools] = useState([])
  const [school, setSchool] = useState({})
  const [saved, setSaved] = useState(false)
  const [loading, setLoading] = useState(false)
  const [pageIndex, setPageIndex] = useState(1)
  const [pageCount, setPageCount] = useState(30)
  const [limit, setLimit] = useState(100)
  const [totalRecords, setTotalRecords] = useState(0)
  const [temp, setTemp] = useState(false)
  const [searchValue, setSearchValue] = useState("")

  useEffect(() => {
    fetchAllSchoolsWithPagination()
  }, [saved])

  useEffect(() => {
    fetchAllSchoolsWithPagination()
  }, [pageIndex, limit, searchValue])

  const fetchAllSchoolsWithPagination = async () => {
    try {
      setLoading(true)
      const response = await getAllSchoolWithFullSearchAPI(
        pageIndex,
        limit,
        searchValue
      )
      let { schools } = response.data || {}
      schools = schools || []
      setSchools(schools.rows)
      setTotalRecords(schools.count)
      let pageCount = Math.ceil(schools.count / limit)
      setPageCount(pageCount)
      setTemp(!temp)
      setLoading(false)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Fetching Schools"

      setSchools([])
      setLoading(false)

      return SaveToast({ message, type: "error" })
    }
  }

  const fetchColumnWiseSearchAPI = async query => {
    try {
      setLoading(true)
      const response = await getAllSchoolColumnWiseSearchAPI(
        pageIndex,
        limit,
        query
      )
      let { schools } = response.data || {}
      schools = schools || []
      setSchools(schools.rows)
      setTotalRecords(schools.count)
      let pageCount = Math.ceil(schools.count / limit)
      setPageCount(pageCount)
      setTemp(!temp)
      setLoading(false)
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Fetching Teachers"
      setSchools([])
      setLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const onClickDelete = school => {
    setSchool(school)
    setDeleteModal(true)
  }

  const handleUpdateSchoolStatus = (id, status) => {
    if (!id) {
      return SaveToast({
        message: "Please Enter School Id",
        type: "error",
      })
    }

    return updateSchool(id, {
      sc_status: status,
    })
      .then(response => {
        if (!response?.status) {
          let message =
            response?.message || "There Was A Problem Updating School Status"
          return SaveToast({ message, type: "error" })
        }

        let message = response?.message || "School Status Updated Successfully"
        SaveToast({ message, type: "success" })

        setSaved(prevSaved => !prevSaved)
        return
      })
      .catch(error => {
        let message =
          error?.response?.data?.message ||
          error?.message ||
          "There Was A Problem Updating School Status"
        return SaveToast({ message, type: "error" })
      })
  }

  const handleDeleteSchool = () => {
    if (!school.sc_id) {
      return SaveToast({ message: "Invalid School ID", type: "error" })
    }
    return deleteSchool(school.sc_id)
      .then(response => {
        if (!response?.status) {
          let message =
            response?.message || "There Was A Problem Deleting School"
          return SaveToast({ message, type: "error" })
        }

        let message = response?.message || "School Deleted Successfully"
        SaveToast({ message, type: "success" })

        setSaved(prevSaved => !prevSaved)
        setDeleteModal(false)
        return
      })
      .catch(error => {
        let message =
          error?.response?.data?.message ||
          error?.message ||
          "There Was A Problem Deleting School"
        return SaveToast({ message, type: "error" })
      })
  }

  const handleAddButtonClick = () => {
    props.history.push("/schools/add")
  }

  const columns = useMemo(
    () => [
      {
        Header: "School Name",
        accessor: "sc_schoolName",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "School Type",
        accessor: "sc_schoolType",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "School Id",
        accessor: "sc_schoolId",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Circuit",
        accessor: "sc_circuit",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Phone Number",
        accessor: "sc_phoneNumber",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "sc_status",
        disableFilters: true,
        Cell: cellProps => {
          const cellData = cellProps.row.original
          return (
            <div className="form-check form-switch form-switch-md">
              <input
                type="checkbox"
                className="form-check-input"
                id={`tm_status_checkbox-${cellData.sc_id}`}
                name={`tm_status_checkbox-${cellData.sc_id}`}
                defaultChecked={cellData.sc_status}
                onChange={e => {
                  let { checked, name } = e.target
                  document.getElementById(name).checked = checked
                  return handleUpdateSchoolStatus(cellData.sc_id, checked)
                }}
              />
            </div>
          )
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-dark"
                onClick={() => {
                  props.history.push(
                    "/schools/view/" + cellProps.row.original.sc_id
                  )
                }}
              >
                <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
                <UncontrolledTooltip placement="top" target="viewtooltip">
                  View
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  props.history.push(
                    "/schools/edit/" + cellProps.row.original.sc_id
                  )
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const schoolData = cellProps.row.original
                  onClickDelete(schoolData)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const printColumns = useMemo(
    () => [
      {
        Header: "School Name",
        accessor: "schoolName",
        filterable: false,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "School Type",
        accessor: "schoolType",
        filterable: false,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "School Id",
        accessor: "schoolId",
        filterable: false,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Circuit",
        accessor: "circuit",
        filterable: false,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Phone Number",
        accessor: "phoneNumber",
        filterable: false,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "status",
        disableFilters: true,
        Cell: cellProps => {
          return cellProps?.row?.original?.status ? "Active" : "Inactive"
        },
      },
    ],
    []
  )

  const getSchoolDataForCSV = async () => {
    const response = await getAllSchool()
    return response.data.schools
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteSchool}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="Scholls" breadcrumbItem="School" /> */}
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={schools}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    addButtonLabel="Add School"
                    handleAddButtonClick={handleAddButtonClick}
                    customPageSize={100}
                    className="custom-header-css"
                    dataFetchLoading={loading}
                    canExportCsv={true}
                    csvFileName={`school.csv`}
                    csvLink="/api/v1/admin/school/download/data"
                    canPrint={true}
                    printColumns={printColumns}
                    noDataMessage="No system activity found."
                    pageIndexFromApi={pageIndex}
                    setPageIndexFromApi={setPageIndex}
                    pageCountFromApi={pageCount}
                    setPageCount1={setPageCount}
                    totalRecords={totalRecords}
                    limit={limit}
                    setLimit={setLimit}
                    customPagination={true}
                    setSearchValue={setSearchValue}
                    fetchColumnWiseSearchAPI={fetchColumnWiseSearchAPI}
                    // csvToDownload={getSchoolDataForCSV}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Schools
