import React, { useEffect, useMemo, useState } from "react"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"
import * as Yup from "yup"
import { SaveToast } from "components/Common/SaveToast"
import Select from "react-select"

import {
  Col,
  Row,
  Card,
  CardBody,
  Label,
  Input,
  FormFeedback,
  Button,
} from "reactstrap"
import { Form, Formik } from "formik"

import {
  SimpleStringValue,
  ArrayToStringValue,
} from "helpers/common_helper_functions"
import { getAllParticipantsByTP } from "helpers/backendHelpers/participants"
import { getAllTutors } from "helpers/backendHelpers/tutorManagement"
import {
  createTrainingBatch,
  getTrainingBatch,
  updateTrainingBatch,
} from "helpers/backendHelpers/trainingBatch"

function AddEditDetails(props) {
  document.title = "Batch | LMS Ghana"

  const tp_id = props.location?.state?.tp_id
  const [trainingParticipants, setTrainingParticipants] = useState([])
  const [loading, setLoading] = useState(false)
  const [tutors, setTutors] = useState([])
  const [values, setValues] = useState({})
  const [participantsId, setParticipantId] = useState([])
  const [temp, setTemp] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [batchId, setBatchId] = useState(0)
  const [isView, setIsView] = useState(false)
  const [flag, setFlag] = useState(0)
  const [batchIds, setBatchIds] = useState([])
  const [userTypes, setUserTypes] = useState([])
  const [pageIndex, setPageIndex] = useState(1)
  const [pageCount, setPageCount] = useState(30)
  const [limit, setLimit] = useState(100)
  const [totalRecords, setTotalRecords] = useState(0)

  useEffect(() => {
    fetchAllTrainingParticipants(tp_id, isEdit ? 1 : 0)
    fetchTutors()
  }, [])

  useEffect(() => {
    fetchAllTrainingParticipants(tp_id, isEdit ? 1 : 0)
  }, [pageIndex, limit])

  const [form, setForm] = useState({
    tu_id: "",
    tb_dateTime: "",
  })

  useEffect(() => {
    if (isEdit == 1) {
      fetchAllTrainingParticipants(tp_id, isEdit ? 1 : 0, batchId)
    }
  }, [isEdit])

  useEffect(() => {
    // document.getElementById("batch-creation").classList.add("mm-active")
    let { type, id } = props.match.params || {}
    setBatchId(parseInt(id))
  }, [])

  useEffect(() => {
    if (batchId) {
      fetchBatchCreationForEdit(batchId)
    }
  }, [batchId])

  useEffect(() => {
    batchIds &&
      batchIds.length > 0 &&
      batchIds.map(data => {
        return setValues(pre => ({
          ...pre,
          [`${data}`]: true,
        }))
      })
  }, [batchIds])

  const handleAllChecked = () => {
    batchIds &&
      batchIds.length > 0 &&
      batchIds.map(data => {
        setValues(pre => ({
          ...pre,
          [`${data}`]: true,
        }))
      })
    setTemp(!temp)
  }

  const fetchTutors = async () => {
    try {
      setLoading(true)
      let response = await getAllTutors()
      let { tutors } = response.data || {}
      tutors = tutors || []
      let tutorsName = []
      tutors.map(data => {
        let tutorObj = {
          key: data.tu_id,
          value: data.tu_fullName,
        }
        tutorsName.push(tutorObj)
      })
      setTutors(tutorsName)
      setLoading(false)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Fetching Tutors1"
      setTutors([])
      setLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const fetchBatchCreationForEdit = async ct_id => {
    try {
      let response = await getTrainingBatch(ct_id)

      let { trainingBatch } = response.data || {}
      trainingBatch = trainingBatch || {}
      setBatchIds(
        trainingBatch?.tb_participants ? trainingBatch?.tb_participants : []
      )
      setForm(trainingBatch)

      trainingBatch?.tb_participants &&
        trainingBatch?.tb_participants.map(data => {
          return setValues(pre => ({
            ...pre,
            [`${data}`]: true,
          }))
        })
      setTemp(pre => !pre)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Fetching Content Team Details"

      setForm(form)
      return SaveToast({ message, type: "error" })
    }
  }

  const fetchAllTrainingParticipants = async (id, isEdit, batchId) => {
    try {
      setLoading(true)
      const response = await getAllParticipantsByTP(
        id,
        isEdit,
        batchId,
        pageIndex,
        limit
      )
      let { userDetails } = response.data || {}

      setTrainingParticipants(userDetails.rows)
      setTotalRecords(userDetails.count)
      let pageCount = Math.ceil(userDetails.count / limit)
      setPageCount(pageCount)
      setTemp(!temp)
      setLoading(false)
    } catch (error) {
      const message = error?.message || "There Was A Problem Fetching Content"

      setLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleSave = () => {
    let tempVals = Object.entries(values)
    let finalIds = []

    tempVals.map(([key, val]) => {
      if (val) {
        finalIds.push(key)
      }
    })
    let selectedTps = trainingParticipants.filter(data => {
      return finalIds.find(tp => data?.tps_id == tp)
    })
    selectedTps = selectedTps.map(({ userEmail, username, tps_id }) => ({
      userEmail,
      username,
      tps_id,
    }))

    form.tb_participants = finalIds.toString()
    form.tp_id = tp_id
    form.selectedTps = JSON.stringify(selectedTps)
    if (isEdit) {
      return handleEditBatchSubmit(form)
    } else {
      return handleAddBatchData(form)
    }
  }

  const handleAddBatchData = async form => {
    setLoading(true)
    let response = await createTrainingBatch(form)
    let message = response?.message || "Batch Created Successfully"
    SaveToast({ message, type: "success" })
    setLoading(false)
  }

  const handleEditBatchSubmit = async form => {
    if (!batchId) {
      return SaveToast({
        message: "Please enter Batch Id",
        type: "error",
      })
    }
    try {
      setLoading(true)
      const response = await updateTrainingBatch(batchId, form)
      let message = response?.message || "Batch Updated Successfully"
      SaveToast({ message, type: "success" })
      setLoading(false)
      props.history.push({
        pathname: "/batch-creation",
        state: { tp_id },
      })
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Updating Batch"
      setLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const toggleCheckbox = e => {
    setValues(pre => ({
      ...pre,
      [e.target.name]: e.target.checked,
    }))
    if (e.target.checked) {
      setParticipantId(pre => [...pre, parseInt(e.target.value)])
      setTemp(temp)
    } else {
      setParticipantId(pre => pre.filter(data => data != e.target.value))
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "select",
        accessor: "",
        disableFilters: true,
        Cell: cellProps => {
          const cellData = cellProps?.row?.original
          return (
            <div className="">
              <input
                type="checkbox"
                className="form-check-input"
                id={`tc_status_checkbox-${cellData.tps_id}`}
                name={`${cellData.tps_id}`}
                value={values[`${cellData.tps_id}`]}
                onChange={toggleCheckbox}
                onClick={e => {
                  setValues(pre => ({
                    ...pre,
                    [e.target.name]: e.target.checked,
                  }))
                }}
                defaultChecked={values && values[`${cellData.tps_id}`]}
              />
            </div>
          )
        },
      },
      {
        Header: "User Name",
        accessor: "username",
        filterable: true,
        Cell: cellProps => {
          return <ArrayToStringValue {...cellProps} />
        },
      },
      {
        Header: "User Type",
        accessor: "userType",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "User Email",
        accessor: "userEmail",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Region",
        accessor: "userRegion",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "District",
        accessor: "userDistrict",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      {/* <Breadcrumbs title="Batch Creation" breadcrumbItem="Batch" /> */}
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Formik
                    enableReinitialize={true}
                    initialValues={form}
                    validationSchema={Yup.object({
                      tu_id: Yup.string().required("Please Select Teacher"),

                      tb_dateTime: Yup.string().required(
                        "Please Select Date And Time"
                      ),
                    })}
                    onSubmit={values => {
                      let batchData = values

                      if (isEdit) {
                        return handleEditBatchSubmit(batchId, batchData)
                      } else {
                        return handleSave(batchData)
                      }
                    }}
                  >
                    {({
                      touched,
                      errors,
                      values,
                      handleSubmit,
                      handleBlur,
                      handleChange,
                      validateOnChange,
                    }) => (
                      <>
                        <Form
                          onSubmit={e => {
                            e.preventDefault()
                            handleSubmit(e)
                            return false
                          }}
                        >
                          <Row>
                            <Col>
                              <Row className="mb-3">
                                <Col md={4} sm={6} xs={12} className="mb-3">
                                  <Label className="form-label">
                                    Tutor
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    name="tu_id"
                                    type="select"
                                    className="form-select"
                                    onChange={e => {
                                      setForm({
                                        ...form,
                                        [e.target.name]: e.target.value,
                                      })
                                    }}
                                    onBlur={handleBlur}
                                    value={form.tu_id}
                                    invalid={
                                      touched.tu_id && errors.tu_id
                                        ? true
                                        : false
                                    }
                                  >
                                    <option value="">Select Tutor</option>
                                    {tutors.map(val => {
                                      return (
                                        <option key={val.key} value={val.key}>
                                          {val.value}
                                        </option>
                                      )
                                    })}
                                  </Input>
                                  {!!touched.tu_id && !!errors.tu_id && (
                                    <FormFeedback>{errors.tu_id}</FormFeedback>
                                  )}
                                </Col>
                                <Col md={4} sm={6} xs={12} className="mb-3">
                                  <Label className="form-label">
                                    Select Date And Time{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    name="tb_dateTime"
                                    type="datetime-local"
                                    validate={{
                                      required: { value: true },
                                    }}
                                    onChange={e => {
                                      setForm({
                                        ...form,
                                        [e.target.name]: e.target.value,
                                      })
                                    }}
                                    onBlur={handleBlur}
                                    value={form.tb_dateTime}
                                    invalid={
                                      touched.tb_dateTime && errors.tb_dateTime
                                        ? true
                                        : false
                                    }
                                  />
                                  {!!touched.tb_dateTime &&
                                    !!errors.tb_dateTime && (
                                      <FormFeedback>
                                        {errors.tb_dateTime}
                                      </FormFeedback>
                                    )}
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Form>
                      </>
                    )}
                  </Formik>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  {batchIds && (
                    <TableContainer
                      dataFetchLoading={loading}
                      columns={columns}
                      data={trainingParticipants}
                      isGlobalFilter={true}
                      isSelectAllOption={true}
                      isAddOptions={false}
                      addButtonLabel="Save Participants"
                      customPageSize={10}
                      className="custom-header-css"
                      canExportCsv={true}
                      canPrint={false}
                      handleAllChecked={handleAllChecked}
                      pageIndexFromApi={pageIndex}
                      setPageIndexFromApi={setPageIndex}
                      pageCountFromApi={pageCount}
                      setPageCount1={setPageCount}
                      totalRecords={totalRecords}
                      limit={limit}
                      setLimit={setLimit}
                      customPagination={true}
                      fetchColumnWiseSearchAPI=""
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="mb-3 text-center">
            <Col>
              <Button
                size="md"
                color="danger"
                type="button"
                className="mx-2"
                onClick={() => {
                  props.history.push({
                    pathname: "/batch-creation",
                    state: { tp_id },
                  })
                }}
              >
                Cancel
              </Button>
              <Button
                type="button"
                color="success"
                className="btn me-2"
                size="md"
                onClick={handleSave}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </div>
      </div>

      {/* </div>
      </div> */}
    </React.Fragment>
  )
}

export default AddEditDetails
