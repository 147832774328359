import React, { useMemo, useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"

//import components
// import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

import { SimpleStringValue } from "helpers/common_helper_functions"
import { SaveToast } from "components/Common/SaveToast"

import { Col, Row, UncontrolledTooltip, Card, CardBody } from "reactstrap"

import {
  getAllGESOffice,
  deleteGESOffice,
  updateGESOffice,
} from "helpers/backendHelpers/GESOffice"

const GESOffices = props => {
  //meta title
  document.title = "GES Offices | GES"

  //delete order
  const [deleteModal, setDeleteModal] = useState(false)
  const [saved, setSaved] = useState(false)
  const [loading, setLoading] = useState(false)
  const [gesOffices, setGesOffices] = useState([])
  const [gesOffice, setGESOffice] = useState({})

  useEffect(() => {
    fetchAllGESOffice()
  }, [saved])

  const fetchAllGESOffice = async () => {
    try {
      setLoading(true)
      const response = await getAllGESOffice()
      let { gesOffices } = response.data || {}
      gesOffices = gesOffices || []
      setGesOffices(gesOffices)
      setLoading(false)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Fetching GESOffices"

      setGesOffices([])
      setLoading(false)

      return SaveToast({ message, type: "error" })
    }
  }
  const onClickDelete = office => {
    setGESOffice(office)
    setDeleteModal(true)
  }

  const handleAddButtonClick = () => {
    props.history.push("/gesoffices/add")
  }

  const handleUpdateGESOfficeStatus = (id, status) => {
    if (!id) {
      return SaveToast({
        message: "Please Enter GESOffice Id",
        type: "error",
      })
    }

    return updateGESOffice(id, {
      go_status: status,
    })
      .then(response => {
        if (!response?.status) {
          let message =
            response?.message || "There Was A Problem Updating GESOffice Status"
          return SaveToast({ message, type: "error" })
        }

        let message =
          response?.message || "GESOffice Status Updated Successfully"
        SaveToast({ message, type: "success" })

        setSaved(prevSaved => !prevSaved)
        return
      })
      .catch(error => {
        let message =
          error?.response?.data?.message ||
          error?.message ||
          "There Was A Problem Updating GESOffice Status"
        return SaveToast({ message, type: "error" })
      })
  }

  const handleDeleteGESOffice = () => {
    if (!gesOffice.go_id) {
      return SaveToast({ message: "Invalid GESOffice ID", type: "error" })
    }
    return deleteGESOffice(gesOffice.go_id)
      .then(response => {
        if (!response?.status) {
          let message =
            response?.message || "There Was A Problem Deleting GESOffice"
          return SaveToast({ message, type: "error" })
        }

        let message = response?.message || "GESOffice Deleted Successfully"
        SaveToast({ message, type: "success" })

        setSaved(prevSaved => !prevSaved)
        setDeleteModal(false)
        return
      })
      .catch(error => {
        let message =
          error?.response?.data?.message ||
          error?.message ||
          "There Was A Problem Deleting GESOffice"
        return SaveToast({ message, type: "error" })
      })
  }

  const columns = useMemo(
    () => [
      {
        Header: "Region",
        accessor: "go_region",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "District",
        accessor: "go_district",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      // {
      //   Header: "Director Name",
      //   accessor: "go_directorName",
      //   filterable: true,
      //   Cell: cellProps => {
      //     return <SimpleStringValue {...cellProps} />
      //   },
      // },
      {
        Header: "Circuit",
        accessor: "go_circuit",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      // {
      //   Header: "Circuit Head",
      //   accessor: "go_circuitHeadName",
      //   filterable: true,
      //   Cell: cellProps => {
      //     return <SimpleStringValue {...cellProps} />
      //   },
      // },
      {
        Header: "Town",
        accessor: "go_town",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Email",
        accessor: "go_email",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Phone Number",
        accessor: "go_phoneNumber",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "go_status",
        disableFilters: true,
        Cell: cellProps => {
          const cellData = cellProps.row.original
          return (
            <div className="form-check form-switch form-switch-md">
              <input
                type="checkbox"
                className="form-check-input"
                id={`go_status_checkbox-${cellData.go_id}`}
                name={`go_status_checkbox-${cellData.go_id}`}
                defaultChecked={cellData.go_status}
                onChange={e => {
                  let { checked, name } = e.target
                  document.getElementById(name).checked = checked
                  return handleUpdateGESOfficeStatus(cellData.go_id, checked)
                }}
              />
            </div>
          )
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-4">
              <Link
                to="#"
                className="text-dark"
                onClick={() => {
                  props.history.push(
                    "/gesoffices/view/" + cellProps.row.original.go_id
                  )
                }}
              >
                <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
                <UncontrolledTooltip placement="top" target="viewtooltip">
                  View
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  props.history.push(
                    "/gesoffices/edit/" + cellProps.row.original.go_id
                  )
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const officeData = cellProps.row.original
                  onClickDelete(officeData)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-dark"
                onClick={() => {
                  props.history.push(
                    "/gesofficestaff/" + cellProps.row.original.go_id
                  )
                }}
              >
                <i
                  className="mdi mdi-account-multiple font-size-18"
                  id="stafftooltip"
                />
                <UncontrolledTooltip placement="top" target="stafftooltip">
                  OfficeStaff
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteGESOffice}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="GES" breadcrumbItem="Offices" /> */}
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={gesOffices}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    addButtonLabel="Add GES Office"
                    handleAddButtonClick={handleAddButtonClick}
                    customPageSize={10}
                    className="custom-header-css"
                    canExportCsv={true}
                    csvFileName={`gesoffice.csv`}
                    csvLink="/api/v1/admin/GES-Office/download/data"
                    canPrint={false}
                    dataFetchLoading={loading}
                    noDataMessage={"No system activity found."}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default GESOffices
