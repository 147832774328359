import React, { Fragment, useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table"
import { Table, Row, Col, Button, Input, Label, Spinner } from "reactstrap"
import { Filter, DefaultColumnFilter, SelectColumnFilter } from "./filters"
import ReactToPrint from "react-to-print"
import { getApiConfig } from "../../helpers/authHelper"

import PrintDataTable from "./PrintDataTable"
import axios from "axios"

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  totalRecordsFromApi,
  setSearchValue = "",
}) {
  const count = totalRecordsFromApi
    ? totalRecordsFromApi
    : preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <Col sm={4}>
      <div className="search-box me-2 d-inline-block">
        <div className="position-relative">
          <label htmlFor="search-bar-0" className="search-label">
            <span id="search-bar-0-label" className="sr-only">
              Search this table
            </span>
            <input
              onChange={e => {
                setSearchValue && setSearchValue(e.target.value)
                setValue(e.target.value)
                onChange(e.target.value)
              }}
              id="search-bar-0"
              type="text"
              className="form-control form-control-sm"
              placeholder={`${count} records...`}
              value={value || ""}
            />
          </label>
          <i className="bx bx-search-alt search-icon katon-search-icon"></i>
        </div>
      </div>
    </Col>
  )
}

const TableContainer = ({
  columns,
  data,
  isGlobalFilter,
  isAddOptions,
  isBackOption,
  addButtonLabel,
  isAddUserList,
  handleOrderClicks,
  handleAddButtonClick,
  handleUserClick,
  handleCustomerClick,
  handleBackButtonClick,
  isAddCustList,
  customPageSize,
  className,
  customPageSizeOptions,
  canExportCsv,
  canPrint,
  printColumns,
  dataFetchLoading,
  noDataMessage,
  isSelectAllOption,
  handleAllChecked,
  pageIndexFromApi,
  setPageIndexFromApi,
  pageCountFromApi,
  setPageCount1,
  totalRecords,
  limit,
  setLimit,
  customPagination = false,
  setSearchValue,
  fetchColumnWiseSearchAPI = "",

  csvLink = "",
  csvFileName = "",
}) => {
  const ref = useRef(null)
  const [pageIndexProps, setPageIndexProps] = useState(pageIndexFromApi)
  const [searchQueries, setSearchQueries] = useState({})

  const [isLoading, setIsLoading] = useState(false)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        // sortBy: [
        //   {
        //     desc: true,
        //   },
        // ],
        hiddenColumns: [],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  )

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const onChangeInSelect = event => {
    customPagination && setLimit(Number(event.target.value))
    setPageSize(Number(event.target.value))
  }

  const onChangeInInput = event => {
    if (customPagination) {
      setPageIndexFromApi(1)
      setPageIndexProps(1)
    } else {
      const page = event.target.value ? Number(event.target.value) - 1 : 0
      gotoPage(page)
    }
  }

  const handleSearchChange = (event, column) => {
    // setSearchQueries({})
    setSearchQueries(oldQueries => ({
      ...oldQueries,
      [column]: event.target.value,
    }))
  }

  useEffect(() => {
    const queryInArray = Object.entries(searchQueries)
    let query = ""
    queryInArray.map(data => {
      return (query = query + `&${data[0]}=${data[1]}`)
    })
    if (customPagination) {
      fetchColumnWiseSearchAPI && fetchColumnWiseSearchAPI(query)
    }
  }, [searchQueries])

  const handleDownloadCSV = () => {
    if (!csvLink) return
    setIsLoading(true)

    console.log("Download CSV")

    const url = `${process.env.REACT_APP_API_URL}${csvLink}`
    // const url = `http://localhost:8080${csvLink}`

    axios({
      url: url,
      method: "GET",
      responseType: "blob",
      ...getApiConfig(),
    })
      .then(response => {
        const href = URL.createObjectURL(response.data)
        const link = document.createElement("a")

        link.href = href
        link.setAttribute("download", csvFileName)
        document.body.appendChild(link)
        link.click()

        document.body.removeChild(link)
        URL.revokeObjectURL(href)

        setIsLoading(false)
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
      })
  }

  return (
    <Fragment>
      <Row className="mb-2">
        <Col md={customPageSizeOptions ? 2 : 1}>
          <select
            className="form-select form-select-sm"
            value={pageSize}
            onChange={onChangeInSelect}
          >
            {[100, 200, 300, 400, 500].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        {isGlobalFilter && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            totalRecordsFromApi={totalRecords}
            setSearchValue={setSearchValue}
          />
        )}
        <Col sm="7">
          <div className="text-sm-end">
            {isAddOptions && (
              <Button
                type="button"
                color="success"
                className="btn mb-2 me-2"
                size="sm"
                onClick={handleAddButtonClick}
              >
                {addButtonLabel && (
                  <>
                    <i className="mdi mdi-plus me-1" />
                    {addButtonLabel}
                  </>
                )}
              </Button>
            )}

            {canExportCsv && (
              <Button
                type="button"
                color="dark"
                className="btn ml-2 me-2 mb-2"
                disabled={isLoading}
                size="sm"
                onClick={handleDownloadCSV}
              >
                <i className="mdi mdi-export-variant me-1" />
                {!isLoading ? "Export CSV" : "Loading..."}
              </Button>
            )}

            {isBackOption && (
              <Button
                type="button"
                color="dark"
                size="sm"
                className="btn mb-2 me-2"
                onClick={handleBackButtonClick}
              >
                Back
              </Button>
            )}
            {canPrint && (
              <>
                <ReactToPrint
                  trigger={() => (
                    <Button
                      type="button"
                      color="warning"
                      size="sm"
                      className="btn ml-2 mb-2 me-2"
                      disabled={!rows.length}
                    >
                      <i className="mdi mdi-printer me-1" />
                      Print
                    </Button>
                  )}
                  content={() => ref.current}
                />
                {printColumns?.length && (
                  <div hidden>
                    <PrintDataTable
                      printColumns={printColumns}
                      printData={rows.map(row => row.original)}
                      ref={ref}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </Col>
        {isAddUserList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="dark"
                className="btn mb-2 me-2"
                onClick={handleUserClick}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New User
              </Button>
            </div>
          </Col>
        )}
        {isAddCustList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded mb-2 me-2"
                onClick={handleCustomerClick}
              >
                <i className="mdi mdi-plus me-1" />
                New Customers
              </Button>
            </div>
          </Col>
        )}
      </Row>

      <div className="table-responsive react-table">
        <Table
          bordered
          hover
          {...getTableProps()}
          className={className + " katon-table-container"}
        >
          <thead className="table-light table-nowrap">
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <>
                    <th key={column.id}>
                      <div {...column.getSortByToggleProps()}>
                        {column.render("Header")}
                        {generateSortingIndicator(column)}
                      </div>
                      {/* <Filter column={column} /> */}
                      {!customPagination ? (
                        <Filter column={column} />
                      ) : (
                        <>
                          {column.id != "action" && (
                            <Input
                              value={searchQueries[column.id] || ""}
                              onChange={event =>
                                handleSearchChange(event, column.id)
                              }
                              bsSize="sm"
                              placeholder={`search  ...`}
                            />
                          )}
                        </>
                      )}
                    </th>
                  </>
                ))}
              </tr>
            ))}
          </thead>

          {page?.length > 0 && !dataFetchLoading && (
            <>
              <tbody {...getTableBodyProps()}>
                {page.map(row => {
                  prepareRow(row)
                  return (
                    <Fragment key={row.getRowProps().key}>
                      <tr>
                        {row.cells.map(cell => {
                          return (
                            <td key={cell.id} {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          )
                        })}
                      </tr>
                    </Fragment>
                  )
                })}
              </tbody>
            </>
          )}
        </Table>
      </div>

      {dataFetchLoading ? (
        <>
          <div className="text-center">
            <Spinner color="info" type="grow" />
          </div>
        </>
      ) : (
        <>
          {page?.length === 0 && (
            <>
              <div className="text-center">
                <Label tag="h5">{noDataMessage || "No Data Available"}</Label>
              </div>
            </>
          )}
        </>
      )}

      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Col className="col-md-auto">
          Total:{" "}
          {customPagination ? (
            <strong>{totalRecords}</strong>
          ) : (
            <strong>{preGlobalFilteredRows.length}</strong>
          )}
        </Col>
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="dark"
              size="sm"
              onClick={() => {
                if (customPagination) {
                  setPageIndexFromApi(1)
                  setPageIndexProps(1)
                } else {
                  gotoPage(0)
                }
              }}
              disabled={
                customPagination ? pageIndexProps == 1 : !canPreviousPage
              }
            >
              {"<<"}
            </Button>
            <Button
              color="dark"
              size="sm"
              // onClick={previousPage}
              onClick={() => {
                if (customPagination) {
                  setPageIndexFromApi(pageIndexFromApi - 1)
                  setPageIndexProps(pageIndexProps - 1)
                } else {
                  previousPage()
                }
              }}
              // disabled={!canPreviousPage}
              disabled={
                customPagination ? pageIndexProps == 1 : !canPreviousPage
              }
            >
              {"<"}
            </Button>
          </div>
        </Col>
        <Col className="col-sm-auto d-none d-md-block">
          Page
          <strong>
            {customPagination
              ? `${pageIndexProps} of ${pageCountFromApi}`
              : `${pageIndex + 1} of ${pageOptions.length}`}
          </strong>
        </Col>
        <Col className="col-md-auto">
          <Input
            type="number"
            bsSize="sm"
            min={1}
            style={{ width: 70 }}
            max={pageOptions.length}
            value={customPagination ? pageIndexProps : pageIndex + 1}
            onChange={onChangeInInput}
          />
        </Col>

        <Col className="col-sm-auto">
          <div className="d-flex gap-1">
            <Button
              color="dark"
              size="sm"
              onClick={() => {
                if (customPagination) {
                  setPageIndexFromApi(pageIndexFromApi + 1)
                  setPageIndexProps(pageIndexProps + 1)
                } else {
                  nextPage()
                }
              }}
              // disabled={!canNextPage}
              disabled={
                customPagination
                  ? pageIndexProps == pageCountFromApi
                  : !canNextPage
              }
            >
              {">"}
            </Button>
            <Button
              color="dark"
              size="sm"
              onClick={() => {
                if (customPagination) {
                  setPageIndexFromApi(pageCountFromApi)
                  setPageIndexProps(pageCountFromApi)
                } else {
                  gotoPage(pageCount - 1)
                }
              }}
              // disabled={!canNextPage}
              disabled={
                customPagination
                  ? pageIndexProps == pageCountFromApi
                  : !canNextPage
              }
            >
              {">>"}
            </Button>
          </div>
        </Col>
      </Row>
    </Fragment>
  )
}

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default TableContainer
